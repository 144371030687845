import React, { useEffect, useState, useRef } from "react"

const Experience = ({ allExperience }) => {
    
    const [jobWindowContent, setJobWindowContent] = useState("");
    const [jobButtonList, setJobButtonList] = useState([]);
    const jobButtonContainer = useRef(null);

    useEffect(() => {
        updateJob("Instaclustr");
    },[allExperience])

    const sortExperienceByDate = () => {
        if(allExperience){
            const sortedArray = allExperience.sort(
                (objA, objB) => {
                    const objADate = new Date(objA.StartDate);
                    const objBDate = new Date(objB.StartDate);
                    return Number(objBDate) - Number(objADate);
                }
            )
        }
    }
    
    const updateJob = (jobName) => {
        if (jobName !== jobWindowContent.Workplace && allExperience){
            const currentButtons = Array.from(jobButtonContainer.current.childNodes)
            currentButtons.forEach(child => {
                child.classList.remove("job-picker-button-selected");
            })
            const selectedChild = currentButtons.filter(child => child.textContent === jobName);
            selectedChild[0].classList.add("job-picker-button-selected");
            const currentJob = allExperience.filter(job => job.Workplace === jobName);
            setJobWindowContent(currentJob[0]);
        }
    }

    const generateJobButtonText = () => {
        if(allExperience){
            sortExperienceByDate(allExperience);
    
            const jobButtonContent = []
            allExperience.forEach(job => {
                jobButtonContent.push(job.Workplace);
            })
    
            return jobButtonContent;
        }
    }

    const getYearFromRoleDate = (date) => {
        const roleDate = new Date(date);
        return roleDate.toLocaleString('en-us', { month: 'short' }) + " " + roleDate.getFullYear();
    }

    if(allExperience){
        return(
            <>
            <section className="section-wrapper">
                <h2>My Relevant Experience</h2>
                <div className="card experience-card">
                    <div ref={jobButtonContainer} className="job-picker">
                        {generateJobButtonText().map((job, index) => {
                            return(<button className="job-picker-button" key={index} onClick={() => updateJob(job)}>{job}</button>);
                        })}
                    </div>
                    <div className="card-content experience-card-content">
                        <h3><span className="job-color">{jobWindowContent.Role}</span> at {jobWindowContent.Workplace}</h3>
                        <h4>{getYearFromRoleDate(jobWindowContent.StartDate)} - {jobWindowContent.EndDate ? getYearFromRoleDate(jobWindowContent.EndDate) : "Current"}</h4>
                        <ul>{jobWindowContent ? jobWindowContent.Responsibilities.split("\n").map(item => {return(<li>{item}</li>)}) : null}</ul>
                    </div>
                </div>
            </section>
        </>
        )
    } else {
        return(
            <p>Content not available.</p>
        )
    }
}
export default Experience
